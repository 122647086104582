<svg xmlns="http://www.w3.org/2000/svg"
     width="100%" height="100%"
     [attr.viewBox]="getMancalaViewBox()"
     [attr.transform]="rotation"
     preserveAspectRatio="xMidYMid meet"
     >
    <rect x="0" y="0"
          ry="115" rx="115"
          [attr.width]="getViewBoxWidth()"
          [attr.height]="240"
          fill="blue"
          class="base"
    />
    <g *ngFor="let line of board; index as y">
        <g *ngFor="let content of line; index as x">
            <g id="click-{{ x }}-{{ y }}"
               app-numbered-circle
               [attr.transform]="getPieceTransform(x, y)"
               (click)="onClick(x, y)"
               [x]="x"
               [y]="y"
               [content]="content"
               [spaceClasses]="getSpaceClasses(x, y)"
               [rotation]="getPieceRotation()"
               [secondaryContent]="getHouseSecondaryContent(x, y)"
            ></g>
        </g>
    </g>
    <line        x1="120"                     y1="120" [attr.x2]="getViewBoxWidth() - 120" y2="120" class="base"/>
    <line        x1="120"                     y1="0"          x2="120"                     y2="240" class="base"/>
    <line [attr.x1]="getViewBoxWidth() - 120" y1="0"   [attr.x2]="getViewBoxWidth() - 120" y2="240" class="base"/>

    <g id="store-PLAYER_ZERO"
       (click)="onStoreClick(Player.ZERO)"
       app-numbered-circle
       transform="translate(60 120)"
       [x]="-1"
       [y]="-1"
       [content]="getStoreContent(Player.ZERO)"
       [spaceClasses]="getSpaceClasses(-1, -1)"
       [rotation]="getPieceRotation()"
       [secondaryContent]="getStoreSecondaryContent(Player.ZERO)"
    ></g>
    <g id="store-PLAYER_ONE"
       (click)="onStoreClick(Player.ONE)"
       app-numbered-circle
       [attr.transform]="getSVGTranslation(getViewBoxWidth() - 60, 120)"
       [x]="2"
       [y]="2"
       [content]="getStoreContent(Player.ONE)"
       [spaceClasses]="getSpaceClasses(2, 2)"
       [rotation]="getPieceRotation()"
       [secondaryContent]="getStoreSecondaryContent(Player.ONE)"
    ></g>
</svg>
