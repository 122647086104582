<svg xmlns="http://www.w3.org/2000/svg"
     width="100%" height="100%"
     [attr.viewBox]="getViewBox().toSVGString()"
     preserveAspectRatio="xMidYMid meet"
>
    <g *ngFor="let line of board; index as y">
        <g *ngFor="let spaceContent of line; index as x">
            <g id="click_{{ x }}_{{ y }}"
               (click)="onClick(x, y)">
                <!-- Background -->
                <rect id="space_{{ x }}_{{ y }}"
                      [attr.x]="x * SPACE_SIZE"
                      [attr.y]="y * SPACE_SIZE"
                      [attr.width]="SPACE_SIZE"
                      [attr.height]="SPACE_SIZE"
                      [ngClass]="getSquareClasses(x, y)"
                      class="base"/>
                <!-- Piece -->
                <circle *ngIf="spaceContent !== EMPTY"
                        id="piece_{{ x }}_{{ y }}"
                        [attr.cx]="SPACE_SIZE/2 + (x * SPACE_SIZE)"
                        [attr.cy]="SPACE_SIZE/2 + (y * SPACE_SIZE)"
                        [ngClass]="getPieceClasses(x, y)"
                        [attr.r]="(SPACE_SIZE / 2) - STROKE_WIDTH"
                        class="base"/>
            </g>
        </g>
    </g>
    <rect *ngFor="let coord of targets"
          [attr.x]="coord.x * SPACE_SIZE + (SPACE_SIZE/2 - INDICATOR_SIZE/2)"
          [attr.y]="coord.y * SPACE_SIZE + (SPACE_SIZE/2 - INDICATOR_SIZE/2)"
          [attr.width]="INDICATOR_SIZE"
          [attr.height]="INDICATOR_SIZE"
          (click)="onClick(coord.x, coord.y)"
          class="indicator"/>
</svg>
