<div class="hero-body">
    <p class="title" i18n>User settings</p>
</div>
<div class="box">
    <p class="title">Resetting password</p>
    <div class="block level-left">
        <p class="level-item" i18n>If you forgot your password or want to change it, click on the following button:</p>
        <button id="reset"
                class="button is-primary level-item is-small"
                routerLink="/reset-password"
                i18n>Reset password</button>
    </div>
</div>
