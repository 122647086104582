import { PlayerOrNone } from 'src/app/jscaip/Player';
import { Table } from 'src/app/jscaip/TableUtils';
import { PlayerNumberMap } from 'src/app/jscaip/PlayerMap';
import { PlayerOrNoneGameStateWithTable } from 'src/app/jscaip/state/PlayerOrNoneGameStateWithTable';

export class PenteState extends PlayerOrNoneGameStateWithTable {

    public constructor(board: Table<PlayerOrNone>,
                       public readonly captures: PlayerNumberMap,
                       turn: number)
    {
        super(board, turn);
    }
}
