<svg xmlns="http://www.w3.org/2000/svg"
     width="100%" height="100%"
     [attr.viewBox]="getViewBox().toSVGString()"
     preserveAspectRatio="xMidYMid meet">

    <g *ngFor="let line of displayedState.board; index as y">
        <g *ngFor="let square of line; index as x">
            <g id="square-{{ x }}-{{ y }}"
               [attr.transform]="getSVGTranslation(SPACE_SIZE * x, SPACE_SIZE * y)"
               (click)="selectCoord(x, y)" >
                <rect *ngIf="square.isUnreachable() === false"
                      class="base data-lodestone-square"
                      [ngClass]="getSquareClasses(x, y)"
                      [attr.x]="0"
                      [attr.y]="0"
                      [attr.width]="SPACE_SIZE"
                      [attr.height]="SPACE_SIZE"
                />
                <g *ngIf="square.isUnreachable()"
                   id="square-{{ x }}-{{ y }}-crumbled">
                    <polygon id="square-{{ x }}-{{ y }}-crumbled-left"
                             points="0,0     42,0    17,25    67,75    42,100   0,100   0,0"
                             [ngClass]="getSquareClasses(x, y)"
                             class="base"
                    />
                    <polygon id="square-{{ x }}-{{ y }}-crumbled-right"
                             points="100,0   58,0    35,25    85,75    58,100 100,100 100,0"
                             [ngClass]="getSquareClasses(x, y)"
                             class="base"
                    />
                </g>
                <g *ngIf="getLodestoneInfo(x, y).isPresent() else piece"
                   id="lodestone-{{ x }}-{{ y }}"
                   [attr.class]="square.isUnreachable() ? 'semi-transparent' : ''"
                   app-lodestone-lodestone
                   [lodestoneInfo]="getLodestoneInfo(x, y).get()"
                />
                <ng-template #piece>
                    <circle *ngIf="hasPieceToDraw(x, y)"
                        id="piece-{{ x }}-{{ y }}"
                        class="base"
                        [ngClass]="getPieceClasses(x, y)"
                        [attr.r]="PIECE_RADIUS"
                        [attr.cx]="SPACE_SIZE / 2"
                        [attr.cy]="SPACE_SIZE / 2"/>
                </ng-template>
            </g>
        </g>
    </g>

    <g *ngFor="let capture of viewInfo.capturesToPlace; index as x"
       id="capture-{{ x }}"
       [attr.transform]="getCaptureTransform(x)" >
        <circle class="base"
                [ngClass]="capture.pieceClasses"
                [attr.r]="PIECE_RADIUS"
                cx="0"
                cy="0"/>
    </g>

    <g *ngIf="isInteractive"
       id="available-lodestones">
        <g *ngFor="let lodestone of viewInfo.availableLodestones; index as x"
            id="lodestone-{{ lodestone.direction }}-{{ lodestone.orientation }}-{{ lodestone.owner.toString() }}"
            [attr.transform]="getAvailableLodestoneTransform(x)"
            (click)="selectLodestone(lodestone)"
            app-lodestone-lodestone
            [lodestoneInfo]="lodestone"
        />
    </g>

    <g *ngFor="let groupInfo of viewInfo.pressurePlateGroupInfos">
        <g *ngFor="let plate of groupInfo.plateInfos">
            <g *ngFor="let square of plate.coords; index as pieceIndex">
                <g id="plate-{{ groupInfo.groupPosition }}-{{ plate.plateIndex }}-{{ pieceIndex }}"
                   (click)="square.temporary ? onTemporaryPressurePlateClick(groupInfo.groupPosition, plate.plateIndex, pieceIndex) : onPressurePlateClick(groupInfo.groupPosition, plate.plateIndex, pieceIndex)" >
                    <rect id="plate-square-{{ groupInfo.groupPosition }}-{{ plate.plateIndex }}-{{ pieceIndex }}"
                          class="base"
                          [ngClass]="square.squareClasses"
                          [attr.x]="SPACE_SIZE * square.coord.x"
                          [attr.y]="SPACE_SIZE * square.coord.y"
                          [attr.width]="SPACE_SIZE"
                          [attr.height]="SPACE_SIZE"
                          [attr.rx]="0.2 * SPACE_SIZE"
                          [attr.ry]="0.2 * SPACE_SIZE"
                    />
                    <circle *ngIf="square.hasPiece"
                            id="plate-piece-{{ groupInfo.groupPosition }}-{{ plate.plateIndex }}-{{ pieceIndex }}"
                            class="base"
                            [ngClass]="square.pieceClasses"
                            [attr.r]="PIECE_RADIUS"
                            [attr.cx]="(SPACE_SIZE * square.coord.x) + (SPACE_SIZE / 2)"
                            [attr.cy]="(SPACE_SIZE * square.coord.y) + (SPACE_SIZE / 2)"
                    />
                </g>
            </g>
        </g>
    </g>

    <rect *ngIf="selectedCoord.isPresent()"
          id="selection-{{ selectedCoord.get().x }}-{{ selectedCoord.get().y }}"
          class="base no-fill selected-stroke"
          [attr.x]="SPACE_SIZE * selectedCoord.get().x"
          [attr.y]="SPACE_SIZE * selectedCoord.get().y"
          [attr.width]="SPACE_SIZE"
          [attr.height]="SPACE_SIZE"/>
</svg>
