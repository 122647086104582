<svg xmlns="http://www.w3.org/2000/svg"
     width="100%" height="100%"
     viewBox="-3 -3 606 606"
     preserveAspectRatio="xMidYMid meet">

     <g *ngFor="let line of board; index as y">
        <g *ngFor="let spaceContent of line; index as x"
           id="click_{{ x }}_{{ y }}"
           [attr.transform]="getTriangleTranslationAtXY(x, y)"
           (click)="onClick(x, y)"
        >
            <g *ngIf="spaceContent.isReachable()">
                <g *ngIf="spaceContent.isPlayer()">
                    <polygon id="pyramid_{{ x }}_{{ y }}"
                             [attr.points]="getPyramidPointsAtXY(x, y)"
                             [ngClass]="getPlayerClassAtXY(x, y)"
                             class="base mid-stroke"
                    />
                </g>
                <g *ngIf="spaceContent.isPlayer() === false">
                    <polygon *ngIf="(x + y) % 2 === 1"
                             id="triangle_{{ x }}_{{ y }}"
                             [attr.points]="getTrianglePointsAtXY(x, y)"
                             class="base mid-stroke"
                    />
                    <polygon *ngIf="(x + y) % 2 === 0"
                             id="triangle_{{ x }}_{{ y }}"
                             [attr.points]="getTrianglePointsAtXY(x, y)"
                             class="base mid-stroke background2"
                    />
                </g>
            </g>
        </g>
    </g>

    <ng-container *ngIf="chosenCoord.isPresent() else lastMove">
        <polygon *ngFor="let possibleLanding of possibleLandings"
                 id="possible_landing_{{ possibleLanding.x }}_{{ possibleLanding.y }}"
                 [attr.points]="getTrianglePointsAt(possibleLanding)"
                 [attr.transform]="getTriangleTranslationAt(possibleLanding)"
                 class="base no-fill mid-stroke selected-stroke"/>
        <polygon *ngIf="chosenCoord.isPresent()"
                 id="chosen_coord_{{ chosenCoord.get().x }}_{{ chosenCoord.get().y }}"
                 [attr.points]="getTrianglePointsAt(chosenCoord.get())"
                 [attr.transform]="getTriangleTranslationAt(chosenCoord.get())"
                 class="base no-fill mid-stroke selected-stroke"/>
    </ng-container>

    <ng-template #lastMove>
        <ng-container *ngIf="lastMoved.isPresent()">
            <polygon [attr.points]="getTrianglePointsAt(lastCoord.get())"
                     [attr.transform]="getTriangleTranslationAt(lastCoord.get())"
                     class="base no-fill mid-stroke moved-stroke"/>
            <polygon [attr.points]="getTrianglePointsAt(lastMoved.get())"
                     [attr.transform]="getTriangleTranslationAt(lastMoved.get())"
                     class="base no-fill mid-stroke moved-stroke"/>
        </ng-container>
    </ng-template>
</svg>
