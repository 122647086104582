<div class="box">
    <p class="title" i18n>Password reset</p>
    <div class="field is-horizontal">
        <div class="field-label is-normal">
            <label class="label" for="email" i18n>Email</label>
        </div>
        <div class="field-body">
            <div class="field">
                <div class="control">
                    <input id="email"
                           class="input"
                           name="email"
                           [(ngModel)]="email"
                           [ngModelOptions]="{standalone: true}"
                           i18n-placeholder placeholder="Email"
                           type="email"
                           autofocus
                           required />
                    <p class="help" i18n>An email will be sent with instruction to reset your password.</p>
                    <p id="successMessage"
                       class="help is-success"
                       *ngIf="success"
                       i18n>The email has been sent, please follow the instructions from that email.</p>
                </div>
            </div>
        </div>
    </div>
    <button id="resetPasswordButton"
            class="button is-primary is-fullwidth block"
            (click)="resetPassword()"
            [disabled]="email === ''"
            i18n>Reset password</button>
    <div *ngIf="errorMessage.isPresent()" class="message is-danger is-fullwidth" role="alert">
        <div class="message-header" i18n>Error</div>
        <div id="errorMessage" class="message-body">{{ errorMessage.get() }}</div>
    </div>
</div>
