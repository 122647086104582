<div class="tabs is-toggle is-fullwidth">
    <ul>
        <li ngClass="if (currentTab === 'games') 'is-active'">
            <a id="tab-games" (click)="selectTab('games')" i18n>Games</a>
        </li>
        <li ngClass="if (currentTab === 'create') 'is-active'">
            <a id="tab-create" [ngClass]="createTabClasses" (click)="selectTab('create')" i18n>Create</a>
        </li>
        <li ngClass="if (currentTab === 'chat') 'is-active'">
            <a id="tab-chat" (click)="selectTab('chat')" i18n>Chat</a>
        </li>
    </ul>
</div>

<div *ngIf="currentTab === 'games'"
     id="game-list-tab"
     class="box">
    <div class="table-container">
        <table class="table is-fullwidth is-hoverable is-striped">
            <thead>
                <tr>
                    <th class="th" scope="col" i18n>Game</th>
                    <th class="th" scope="col" i18n>First player</th>
                    <th class="th" scope="col" i18n>Second player</th>
                    <th class="th" scope="col" i18n>Turn</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let part of activeParts; index as i"
                    id="part-{{ i }}"
                    (click)="joinGame(part)">
                    <td class="td data-game-name">{{ part.getGameName() }}</td>
                    <td class="td" id="part-of-{{ part.data.playerZero.name}}">{{ part.data.playerZero.name + ' (' + part.getPlayerZeroFloorElo() + ')' }}</td>
                    <td class="td">
                        <ng-container *ngIf="part.data.playerOne else waitingForOpponent">{{ part.data.playerOne.name }}</ng-container>
                        <ng-template #waitingForOpponent>
                            <ng-container i18n>Waiting for opponent</ng-container>
                        </ng-template>
                    </td>
                    <td class="td data-turn">{{ part.data.turn+1 }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="currentTab === 'create'"
     id="game-creator-tab"
     class="box">
    <app-online-game-selection id="online-game-selection"></app-online-game-selection>
</div>

<div *ngIf="currentTab === 'chat'"
     id="chat-tab"
     class="columns">
    <div class="column is-12">
        <div id="chat" class="box">
            <app-chat chatId="server" [turn]="-1"></app-chat>
        </div>
    </div>
</div>
