<nav class="navbar is-primary" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a class="navbar-item" [routerLink]="['/']">
            <img id="logo" src="assets/images/logo.png" width="28" height="28">
        </a>
        <a role="button"
           class="navbar-burger"
           [ngClass]="showMenu ? 'is-active' : ''"
           aria-label="menu"
           aria-expanded="false"
           data-target="menu"
           (click)="showMenu = !showMenu">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>

    <div id="menu" class="navbar-menu" [ngClass]="showMenu ? 'is-active' : ''" (click)="showMenu = !showMenu">

        <div class="navbar-start">
            <a id="home" class="navbar-item" [routerLink]="['/']" i18n>Home</a>
            <div class="navbar-item has-dropdown is-hoverable">
                <a class="navbar-link" i18n>Play online</a>
                <div class="navbar-dropdown">
                    <a class="navbar-item" [routerLink]="['/play']" i18n>Create a game</a>
                    <a class="navbar-item" [routerLink]="['/lobby']" i18n>Join a game</a>
                </div>
            </div>
            <div class="navbar-item has-dropdown is-hoverable">
                <a id="playOffline" class="navbar-link" i18n>Play offline</a>
                <div class="navbar-dropdown">
                    <a id="playLocally" class="navbar-item" [routerLink]="['/local']" i18n>Create a game</a>
                    <a id="tutorial" class="navbar-item" [routerLink]="['/tutorial']" i18n>Learn the rules</a>
                </div>
            </div>
        </div>

        <div class="navbar-end">
            <a *ngIf="currentGame.isPresent()"
               id="currentGameLink"
               class="navbar-item"
               (click)="navigateToPart()">
                <ng-container *ngIf="currentGame.get().role === 'Observer' || currentGame.get().role === 'Candidate' else activeUser" i18n>{{ getCurrentGameName() }} by {{ currentGame.get().opponent?.name }}</ng-container>
                <ng-template #activeUser>
                    <ng-container *ngIf="currentGame.get().opponent else waitingForOpponent" i18n>{{ getCurrentGameName() }} against {{ currentGame.get().opponent?.name }}</ng-container>
                    <ng-template #waitingForOpponent>
                        <ng-container i18n>{{ getCurrentGameName() }} (waiting for opponent)</ng-container>
                    </ng-template>
                </ng-template>
            </a>

            <a class="navbar-item" [routerLink]="['/settings']" i18n-data-tooltip data-tooltip="Settings"><fa-icon [icon]="faCog"></fa-icon></a>

            <div class="navbar-item">
                <ng-container *ngIf="loading else showUsernameOrLogin">
                    <fa-icon [icon]="faSpinner" class="navbar-item fa-spin"></fa-icon>
                </ng-container>
                <ng-template #showUsernameOrLogin>
                    <ng-container *ngIf="username.isPresent() else connect">
                        <div class="navbar-item has-dropdown is-hoverable">
                            <a id="connectedUserName" class="navbar-link">{{ username.get() }}</a>
                            <div class="navbar-dropdown">
                                <a class="navbar-item" id="account" routerLink="/account" i18n>Account</a>
                                <a class="navbar-item" id="logout" (click)="logout()" i18n>Log out</a>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #connect>
                        <div class="buttons">
                            <a id="register" class="button is-primary" [routerLink]="['/register']">
                                <strong i18n>Register</strong>
                            </a>
                            <a id="login" class="button is-light" [routerLink]="['/login']" i18n>Log in</a>
                        </div>
                    </ng-template>
                </ng-template>
            </div>
        </div>
    </div>
</nav>
