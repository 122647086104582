<svg xmlns="http://www.w3.org/2000/svg"
     width="100%" height="100%"
     [attr.viewBox]="getViewBox().toSVGString()"
     preserveAspectRatio="xMidYMid meet">
    <g #remainingPieces
       *ngFor="let player of Player.PLAYERS">
        <g *ngFor="let piece of getRemainingPieces(player); index as pieceIdx">
            <circle id="piece-{{ player.toString() }}-{{ piece.toString() }}-{{ pieceIdx }}"
                    (click)="onPieceClick(player, piece, pieceIdx)"
                    [attr.transform]="'rotate(' + ((this.getPointOfView().getValue() + player.getValue()) * 180) + ' ' + CENTER + ' ' + CENTER + ')'"
                    [attr.r]="getPieceRadius(piece)"
                    [attr.cx]="getRemainingPieceCenterCoord(player, pieceIdx).x"
                    [attr.cy]="getRemainingPieceCenterCoord(player, pieceIdx).y"
                    [ngClass]="getSidePieceClasses(piece, pieceIdx)"
                    class="base no-fill"
                    pointer-events="fill"
            />
        </g>
    </g>
    <g #boardDiv>
        <g *ngFor="let line of board; index as y">
            <g *ngFor="let spaceContent of line; index as x">
                <rect id="click-{{ x }}-{{ y }}"
                      (click)="onBoardClick(x, y)"
                      [attr.x]="SPACE_SIZE * x"
                      [attr.y]="SPACE_SIZE * y"
                      [attr.width]="SPACE_SIZE"
                      [attr.height]="SPACE_SIZE"
                      [ngClass]="getRectClasses(x, y)"
                      class="base"
                />
                <circle *ngFor="let piece of getListPieces(spaceContent)"
                        (click)="onBoardClick(x, y)"
                        [attr.r]="getPieceRadius(piece)"
                        [attr.cx]="getPieceCenter(x)"
                        [attr.cy]="getPieceCenter(y)"
                        [ngClass]="getPieceClasses(piece)"
                        class='base no-fill'
                />
            </g>
        </g>
        <rect *ngIf="chosenCoord.isPresent()"
              id="chosen-{{ chosenCoord.get().x }}-{{ chosenCoord.get().y }}"
              (click)="onBoardClick(chosenCoord.get().x, chosenCoord.get().y)"
              [attr.x]="SPACE_SIZE * chosenCoord.get().x"
              [attr.y]="SPACE_SIZE * chosenCoord.get().y"
              [attr.width]="SPACE_SIZE"
              [attr.height]="SPACE_SIZE"
              class="base no-fill selected-stroke"
              pointer-events="fill"
        />
        <rect *ngFor="let coord of victoryCoords"
              id="victory-{{ coord.x }}-{{ coord.y }}"
              class="base no-fill victory-stroke mid-stroke"
              [attr.x]="SPACE_SIZE * coord.x"
              [attr.y]="SPACE_SIZE * coord.y"
              [attr.width]="SPACE_SIZE"
              [attr.height]="SPACE_SIZE"
        />
    </g>
</svg>
