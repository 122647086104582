import { FirebaseConfig } from 'src/environments/environment-type';

export const firebaseConfig: FirebaseConfig = {
    apiKey: 'AIzaSyCgOzdBWz58aFnjCFGzH_KiLMtA8PXaQhc',
    authDomain: 'everyboard-dev.firebaseapp.com',
    projectId: 'everyboard-dev',
    storageBucket: 'everyboard-dev.appspot.com',
    messagingSenderId: '579824736515',
};

