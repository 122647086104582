import { Component } from '@angular/core';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
})
export class AccountComponent {

    public constructor() {}

}
