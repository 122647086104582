<svg xmlns="http://www.w3.org/2000/svg"
     width="100%" height="100%"
     [attr.viewBox]="getViewBox().toSVGString()"
     [attr.transform]="rotation"
     preserveAspectRatio="xMidYMid meet"
>
    <g *ngFor="let line of board; index as y">
        <g *ngFor="let spaceContent of line; index as x">
            <g id="click_{{ x }}_{{ y }}"
               (click)="onClick(x, y)">
                <!-- Background -->
                <rect [attr.x]="x * SPACE_SIZE"
                      [attr.y]="y * SPACE_SIZE"
                      [attr.width]="SPACE_SIZE"
                      [attr.height]="SPACE_SIZE"
                      class="base-no-fill"
                      [attr.fill]="backgroundColor(x, y)"
                      fill-opacity="0.7"
                />
                <!-- Piece -->
                <circle *ngIf="spaceContent !== UNOCCUPIED"
                        [attr.cx]="SPACE_SIZE/2 + (x * SPACE_SIZE)"
                        [attr.cy]="SPACE_SIZE/2 + (y * SPACE_SIZE)"
                        [ngClass]="piecePlayerClass(spaceContent)"
                        [attr.r]="(SPACE_SIZE / 2) - STROKE_WIDTH"
                        class="base-no-fill"
                />
                <rect *ngIf="spaceContent !== UNOCCUPIED"
                      [attr.x]="SPACE_SIZE/2 + (x * SPACE_SIZE) - SPACE_SIZE/8"
                      [attr.y]="SPACE_SIZE/2 + (y * SPACE_SIZE) - SPACE_SIZE/8"
                      [attr.rx]="isPlayerZero(spaceContent) ? SPACE_SIZE : 0"
                      [attr.ry]="isPlayerZero(spaceContent) ? SPACE_SIZE : 0"
                      [attr.width]="SPACE_SIZE/4"
                      [attr.height]="SPACE_SIZE/4"
                      [attr.fill]="pieceColor(spaceContent)"
                      class="no-stroke"
                />
            </g>
        </g>
    </g>
    <!-- Highlight last move -->
    <g *ngIf="lastPieceMove.isPresent()">
        <rect id="last_move_start_{{ lastPieceMove.get().getStart().x }}_{{ lastPieceMove.get().getStart().y }}"
              [attr.x]="lastPieceMove.get().getStart().x * SPACE_SIZE"
              [attr.y]="lastPieceMove.get().getStart().y * SPACE_SIZE"
              [attr.width]="SPACE_SIZE"
              [attr.height]="SPACE_SIZE"
              class="base no-fill mid-stroke last-move-stroke"/>
        <rect id="last_move_start_{{ lastPieceMove.get().getEnd().x }}_{{ lastPieceMove.get().getEnd().y }}"
              [attr.x]="lastPieceMove.get().getEnd().x * SPACE_SIZE"
              [attr.y]="lastPieceMove.get().getEnd().y * SPACE_SIZE"
              [attr.width]="SPACE_SIZE"
              [attr.height]="SPACE_SIZE"
              class="base no-fill mid-stroke last-move-stroke"/>
    </g>
    <!-- Highlight selected piece -->
    <circle *ngIf="chosen.isPresent()"
            id="selectedPiece"
            [attr.cx]="SPACE_SIZE/2 + (chosen.get().x * SPACE_SIZE)"
            [attr.cy]="SPACE_SIZE/2 + (chosen.get().y * SPACE_SIZE)"
            [attr.r]="(SPACE_SIZE / 2) - STROKE_WIDTH"
            class="base mid-stroke no-fill selected-stroke"/>
</svg>
