<svg xmlns="http://www.w3.org/2000/svg"
     width="100%" height="100%"
     [attr.viewBox]="left + ' ' + up + ' ' + width + ' ' + height">

    <g *ngFor="let line of board; index as y">
        <g *ngFor="let space of line; index as x">
            <polygon id="space_{{ x }}_{{ y }}"
                     [attr.points]="getParallelogramPoints()"
                     [attr.transform]="getTranslationAtXYZ(x, y, 0)"
                     (click)="onClick(x, y)"
                     class="base"
                     [ngClass]="getSpaceClasses(x, y)"/>
        </g>
    </g>
    <g *ngFor="let moveZ of pieceOnBoard; index as z">
        <g *ngFor="let moveYZ of moveZ; index as y">
            <g *ngFor="let piece of moveYZ; index as x">
                <g *ngIf="piece.move.isPresent()"
                   mgp-trexo-half-piece
                   id="half_tile_{{ x }}_{{ y }}_{{ z }}"
                   (click)="onClick(x, y)"
                   [attr.transform]="getTranslationAtXYZ(x, y, z + 1)"
                   [mode]="mode"
                   [coord]="Coord3D.of(x, y, z)"
                   [mustDisplayHeight]="chosenMode === '2D'"
                   [move]="piece.move.get()"
                   [pieceClasses]="getPieceClasses(x, y, z)"/>
                <g *ngIf="piece.isDroppedPiece"
                   mgp-trexo-half-piece
                   [mode]="mode"
                   id="dropped_piece_{{ x }}_{{ y }}_{{ z }}"
                   (click)="onClick(x, y)"
                   [attr.transform]="getTranslationAtXYZ(x, y, z + 1)"
                   [coord]="Coord3D.of(x, y, z)"
                   [pieceClasses]="[currentOpponentClass]"/>
            </g>
        </g>
    </g>
    <g #switchModeIndicator>
        <g *ngIf="chosenMode === '2D' else switchTo2D"
           id="switchTo3D"
           mgp-trexo-half-piece
           (click)="switchToMode('3D')"
           [mode]="TrexoComponent.modeMap['3D']"
           [attr.transform]="get3DSwitcherTransform()"
           [coord]="Coord3D.of(10.5, 8.5, 0)"
           [pieceClasses]="['player0-fill']"/>
        <ng-template #switchTo2D>
            <g mgp-trexo-half-piece
               id="switchTo2D"
               (click)="switchToMode('2D')"
               [mode]="TrexoComponent.modeMap['2D']"
               [attr.transform]="getTranslationAtXYZ(12, 9, 0)"
               [coord]="Coord3D.of(12, 9, 0)"
               [pieceClasses]="[currentPlayerClass]"/>
        </ng-template>
    </g>
</svg>